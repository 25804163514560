import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Row, Col } from "reactstrap";

const STATUS_COLORS = {
  CANDIDAT_ADMIS: "#4CAF50",
  CANDIDAT_NON_ADMIS: "#F44336",
  NOUVEAU: "#6A1E55",
  EN_COURS_ANALYSE: "#6EC207",
  CANDIDATURE_RETENUE: "#B1004B",
  CANDIDATURE_NON_RETENUE: "#608BC1",
};

const GENRE_COLORS = {
  HOMME: "#A0D683",
  FEMME: "#CB6040",
};

const STATUS_ADMIS_COLORS = {
  CANDIDAT_ADMIS: "#FFE700",
  CANDIDAT_NON_ADMIS: "#347928",
};

const legendLabels = {
  CANDIDAT_ADMIS: "Candidatures admises",
  CANDIDAT_NON_ADMIS: "Candidatures non admises",
  NOUVEAU: "Nouveau",
  EN_COURS_ANALYSE: "En Cours d'analyse",
  CANDIDATURE_RETENUE: "Candidatures retenues",
  CANDIDATURE_NON_RETENUE: "Candidatures non retenues",
};

// Custom Legend Renderer
const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        flexWrap: "wrap",
        listStyleType: "none",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`legend-item-${index}`}
          style={{
            margin: "0 10px",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: entry.color,
              marginRight: "8px",
            }}
          />
          {legendLabels[entry.value] || entry.value}
        </li>
      ))}
    </ul>
  );
};

const Charts = ({
  genreReport,
  typeHandiReport,
  statusReport,
  genreStatusReport,
}) => {
  const filteredStatusReport = statusReport?.filter(
    (entry) =>
      entry.status !== "CANDIDAT_NON_ADMIS" && entry.status !== "CANDIDAT_ADMIS"
  );
  const filteredAdmisReport = statusReport?.filter(
    (entry) =>
      entry.status !== "NOUVEAU" &&
      entry.status !== "EN_COURS_ANALYSE" &&
      entry.status !== "CANDIDATURE_RETENUE" &&
      entry.status !== "CANDIDATURE_NON_RETENUE"
  );

  const modifiedGenreStatusReport = genreStatusReport?.map((entry) => ({
    ...entry,
    admisLabel: `${entry.countAdmis} (${entry.admisPercentage.toFixed(1)}%)`,
    nonAdmisLabel: `${entry.countNonAdmis} (${entry.nonAdmisPercentage.toFixed(
      1
    )}%)`,
  }));
  return (
    <div className="chart-container m-3">
      <Row className="mb-5">
        <Col>
          <h5 className="text-center mb-3">
            Nombre d'inscrits par type de handicap
          </h5>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={typeHandiReport} margin={{ top: 50 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="typeHandi" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="count"
                fill="#FF6500"
                animationDuration={500}
                barSize={30}
              >
                <LabelList
                  dataKey="count"
                  position="top"
                  content={({ x, y, value, index }) => {
                    const totalCount = typeHandiReport.reduce(
                      (acc, entry) => acc + entry.count,
                      0
                    );
                    const percentage = totalCount
                      ? ((value / totalCount) * 100).toFixed(1)
                      : 0;
                    return (
                      <text x={x} y={y} dy={-6} fill="#000" textAnchor="middle">
                        {`${value} (${percentage}%)`}
                      </text>
                    );
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="text-center mb-3">Nombre d'inscrits par genre</h5>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={genreReport}
                dataKey="count"
                nameKey="genre"
                cx="50%"
                cy="50%"
                outerRadius={120}
                label={({ value }) => {
                  const totalCount = filteredStatusReport.reduce(
                    (acc, entry) => acc + entry.count,
                    0
                  );
                  const percentage = totalCount
                    ? ((value / totalCount) * 100).toFixed(1)
                    : 0;
                  return `${value} (${percentage}%)`;
                }}
              >
                {genreReport?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={GENRE_COLORS[entry.genre]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </Col>
        <Col>
          <h5 className="text-center mb-3">
            Nombre d'inscrit par statut de candidature
          </h5>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={filteredStatusReport}
                dataKey="count"
                nameKey="status"
                cx="50%"
                cy="50%"
                outerRadius={120}
                label={({ value }) => {
                  const totalCount = filteredStatusReport.reduce(
                    (acc, entry) => acc + entry.count,
                    0
                  );
                  const percentage = totalCount
                    ? ((value / totalCount) * 100).toFixed(1)
                    : 0;
                  return `${value} (${percentage}%)`;
                }}
              >
                {filteredStatusReport?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUS_COLORS[entry.status]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="text-center mb-3">Resultats definitifs par genre</h5>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={modifiedGenreStatusReport} margin={{ top: 50 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="genre" />
              <YAxis />
              <Tooltip />
              <Legend content={renderLegend} />

              <Bar
                dataKey="countAdmis"
                fill={STATUS_COLORS.CANDIDAT_ADMIS}
                animationDuration={500}
                name="Candidatures admises"
                barSize={30}
              >
                <LabelList dataKey="admisLabel" position="top" />{" "}
              </Bar>

              <Bar
                dataKey="countNonAdmis"
                fill={STATUS_COLORS.CANDIDAT_NON_ADMIS}
                animationDuration={500}
                name="Candidatures non admises"
                barSize={30}
              >
                <LabelList dataKey="nonAdmisLabel" position="top" />{" "}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Col>
        <Col>
          <h5 className="text-center mb-3">
            Resultat définitif des candidatures
          </h5>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={filteredAdmisReport}
                dataKey="count"
                nameKey="status"
                cx="50%"
                cy="50%"
                outerRadius={120}
                label={({ value }) => {
                  const totalCount = filteredAdmisReport.reduce(
                    (acc, entry) => acc + entry.count,
                    0
                  );
                  const percentage = totalCount
                    ? ((value / totalCount) * 100).toFixed(1)
                    : 0;
                  return `${value} (${percentage}%)`;
                }}
              >
                {filteredAdmisReport?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUS_ADMIS_COLORS[entry.status]} // Assign color based on status
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </div>
  );
};

export default Charts;
