import React from 'react';
import { Link } from 'react-router-dom';
import { CANDIDATURE_PAGE, DASHBOARD_PAGE, TRANSACTION_PAGE } from '../../routes/routeConstants';

const AdminNav = () => {
  return (
    <div className="admin-nav bg-light py-2 px-4 border-bottom">
      <div className="d-flex gap-3">
        <Link to={DASHBOARD_PAGE} className="btn btn-primary">
          <i className="ri-dashboard-line me-1"></i>Tableau de bord
        </Link>
        <Link to={CANDIDATURE_PAGE} className="btn btn-primary">
          <i className="ri-user-line me-1"></i>Candidatures
        </Link>
        <Link to={TRANSACTION_PAGE} className="btn btn-primary">
          <i className="ri-money-dollar-circle-line me-1"></i>Transactions
        </Link>
      </div>
    </div>
  );
};

export default AdminNav;