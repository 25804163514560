
// Authentication Module
import { rootReducer } from "saga-slice";
import userSlice from "../saga/userSlice";
import inscriptionSlice from "../saga/inscriptionSlice";
import dataSlice from "../saga/dataSlice";
import transactionSlice from "../saga/transactionSlice";

const modules = [userSlice, inscriptionSlice, dataSlice, transactionSlice];

const appReducer = rootReducer(modules, {
  // public
  
});

export default appReducer;
