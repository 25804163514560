import React, { Component } from "react";

import { connect } from "react-redux";
//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";
import withRouter from "../Common/withRouter";


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuVisible: !props.isMobile
        };
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            isMenuVisible: !prevState.isMenuVisible
        }));
    }

    render() {
        const { isMobile } = this.props;
        const { isMenuVisible } = this.state;

        return (
            <React.Fragment>
                {isMobile && (
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="btn btn-link menu-toggle"
                            onClick={this.toggleMenu}
                        >
                            <i className="ri-menu-line"></i>
                        </button>
                    </div>
                )}
                
                <div className={`vertical-menu ${isMobile && !isMenuVisible ? 'd-none' : ''}`}>
                    <div data-simplebar className="h-100">
                        {this.props.type !== "condensed" ? (
                            <SimpleBar style={{ maxHeight: "100%" }}>
                                <SidebarContent />
                            </SimpleBar>
                        ) : <SidebarContent />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return {
        layout: state.Layout
    };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));

