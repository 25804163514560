import React from "react";
import { Navigate } from "react-router-dom";
import { AxiosInterceptor } from "../helpers/AxiosInterceptor";
import { useJwt } from "react-jwt";

const AppRoute = (props) => {
	const authUser = localStorage.getItem("authUser");
	const parsedAuthUser = JSON.parse(authUser);
	const { decodedToken } = useJwt(parsedAuthUser?.accessToken);

	const hasAnyRole = () => {
		if (props.roles?.length === 0) {
			return true;
		}
		if (!decodedToken) {
			return false;
		}
		let currentRoles = decodedToken?.roles ?? [];

		localStorage.setItem("roles", currentRoles);
		const found = props.roles?.some(r => currentRoles.indexOf(r) >= 0);
		return found;
	}

	if (!localStorage.getItem("authUser")) {
		return (
			<Navigate to={{ pathname: "/login", state: { from: props.location } }} />
		);
	}
	console.log(decodedToken)

	return (
		hasAnyRole() ?
			<AxiosInterceptor>
				<React.Fragment>
					
					{props.children}
				</React.Fragment>
			</AxiosInterceptor> :
			<></>
	);
}

export default AppRoute;

