import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import { connect, useSelector } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts
import VerticalLayout from "./components/VerticalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import AppRoute from "./routes/route";
import "./i18n";
import MessagePage from "./modules/alerts/MessagePage";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	/**
   * Returns the layout
   */
	getLayout = () => {
		let layoutCls = VerticalLayout;
		return layoutCls;
	};

	render() {
		const Layout = this.getLayout();
		return (
			<React.Fragment>
				<Routes>
					{publicRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<NonAuthLayout>
									{route.component}
								</NonAuthLayout>
							}
							key={idx}
							exact={true}
						/>
					))}

					{authProtectedRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<AppRoute roles={route.roles}>
									<Layout>{route.component}</Layout>
								</AppRoute>}
							key={idx}
							exact={true}
						/>
					))}
					{/* Default route */}
					{/* <Route
						path="*"
						element={

							<MessagePage />
						}
					/> */}
				</Routes>
			</React.Fragment>
		);
	}
}

export default App;
