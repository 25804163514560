import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Container } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { transactionActions } from "../../saga/transactionSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight, FaFileExcel } from "react-icons/fa";
import Swal from "sweetalert2";
import AdminNav from "../../components/VerticalLayout/AdminNav";
import { jwtDecode } from "jwt-decode";

function Transactions() {
  const { t } = useTranslation("translation");
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Track selected row IDs
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    isFetching,
    totalPages,
    transactions,
    totalElements,
    pageNumber,
    pageSize,
  } = useSelector((state) => state.transaction);
  const criteria = { companyCode: "" };
  const [page, setPage] = useState(pageNumber); // Track current page
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      let payload = {
        criteria,
        page,
        size: pageSize,
        onSuccess: (data) => {
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setLoading(false);
        },
      };
      dispatch(transactionActions.search(payload));
    }, 1500);
  }, [page]);

  const statusStyles = (status, paiement) => {
    return {
      display: "inline-block",
      padding: "5px 10px",
      borderRadius: "8px",
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: "center",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light shadow to give a 3D effect
      margin: "4px 0",
      backgroundColor: paiement
        ? getStatusPaiementBackgroundColor(status)
        : getStatusBackgroundColor(status),
      color: "white",
    };
  };
  const getStatusPaiementBackgroundColor = (status) => {
    if (status === "FRAIS_CERTIFICAT") return "grey";
    if (status === "FRAIS_CANDIDATURE") return "#074799";
  };

  const getStatusText = (status) => {
    if (status === "FRAIS_CERTIFICAT") return "Frais de certificat";
    if (status === "FRAIS_CANDIDATURE") return "Frais de candidature";
    if (status === "SUCCESSFUL") return "Réussi";
    if (status === "FAILED") return "Échoué";
    if (status === "PENDING") return "En attente";
  };
  // Helper function to set different background colors for each status
  const getStatusBackgroundColor = (status) => {
    if (status === "PENDING") return "#0056b3"; // Blue for Draft
    if (status === "SUCCESSFUL") return "green"; // Green for Approved
    if (status === "FAILED") return "red"; // Red for Canceled
  };

  const columns = useMemo(
    () => [
      {
        Header: t("inscription.numero"),
        accessor: "inscriptionNumero",
        disableFilters: true,
      },
      {
        Header: t("transaction.externalId"),
        accessor: "externalId",
        disableFilters: true,
      },
      {
        Header: t("transaction.date"),
        accessor: "lastModifiedDate",
        disableFilters: true,
      },

      {
        Header: t("inscription.telephone"),
        accessor: "partyId",
        disableFilters: true,
      },
      {
        Header: t("inscription.name"),
        accessor: "inscription.nom",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <React.Fragment>
              {cellProps.inscriptionNom} {cellProps.inscriptionPrenom}
            </React.Fragment>
          );
        },
      },
      {
        Header: t("transaction.amount"),
        accessor: "amount",
        disableFilters: true,
      },
      {
        Header: t("transaction.typePaiment"),
        accessor: "typePaiment",
        disableFilters: true,
        Cell: ({ value }) => (
          <div style={statusStyles(value, true)}>{getStatusText(value)}</div>
        ),
      },
      {
        Header: t("transaction.status"),
        accessor: "status",
        disableFilters: true,
        Cell: ({ value }) => (
          <div style={statusStyles(value)}>{getStatusText(value)}</div>
        ),
      },
    ],
    [t]
  );

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const getItemRange = () => {
    const start = page * 10 + 1;
    const end = Math.min((page + 1) * 10, totalElements);
    return `${start}-${end} of ${totalElements}`;
  };

  const handleExportExcel = () => {
    let payload = {
      criteria,
      page: pageNumber,
      size: pageSize,
      onSuccess: (data) => {
        console.log(data);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = "transactions.xlsx";
        link.click();

        Swal.fire({
          icon: "success",
          title: "Exportation réussie",
          text: "Le fichier Excel des transactions a été généré et téléchargé avec succès.",
          confirmButtonText: "Super !",
        });
      },
      onError: (error) => {
        console.error(error);

        Swal.fire({
          icon: "error",
          title: "Échec de l'exportation",
          text: `Une erreur s'est produite lors de la génération du fichier des transactions. Veuillez réessayer plus tard.
                 Erreur : ${error.message || "Erreur inconnue"}`,

          confirmButtonText: "Ok",
        });
      },
    };
    dispatch(transactionActions.generateExcelTransaction(payload));
  };

  const hasRole = (role) => {
    const authUser = localStorage.getItem("authUser");
    const parsedAuthUser = JSON.parse(authUser);
    const decoded = jwtDecode(parsedAuthUser?.accessToken);
    const roles = decoded?.roles ?? [];
    console.log(roles);
    const found = [role]?.some((r) => roles?.indexOf(r) >= 0);
    return found;
  };

  const isAdmin = hasRole("ROLE_ADMIN") || hasRole("ROLE_SUPER_ADMIN");


  return (
    <React.Fragment>
      {isFetching || loading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {isAdmin ? <AdminNav /> : null}

            <Card>
              <h3 className="m-4">{t("transaction.transactions")}</h3>
              <CardBody>
                <div className="pagination-container">
                  <span className="pagination-range">{getItemRange()}</span>

                  <button
                    onClick={handlePreviousPage}
                    disabled={page === 0}
                    className="pagination-icon-button"
                  >
                    <FaChevronLeft />
                  </button>

                  <button
                    onClick={handleNextPage}
                    disabled={page >= totalPages - 1}
                    className="pagination-icon-button"
                  >
                    <FaChevronRight />
                  </button>
                  <button
                    onClick={handleExportExcel}
                    color="primary"
                    className="excel-button"
                  >
                    <FaFileExcel style={{ marginRight: "5px" }} />
                    {t("Excel")}
                  </button>
                </div>

                <TableContainer
                  columns={columns}
                  data={transactions}
                  isPagination={false}
                  customPageSizeOptions={true}
                  iscustomPageSize={false}
                  isBordered={false}
                  customPageSize={10}
                  selectedRowIds={selectedRowIds}
                  setSelectedRowIds={setSelectedRowIds}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}

export default Transactions;
