import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Label,
  Container,
  Row,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  CardHeader,
  Badge,
} from "reactstrap";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import TableContainer from "../../components/Common/TableContainer";
import PaiementBloc from "./PaiementBloc";
import { useFormik } from "formik";
import { dataActions } from "../../saga/dataSlice";
import "../../assets/css/style.css";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PaimentCertBloc from "./PaimentCertBloc";
import { format, parse } from "date-fns";
import { FaCalendarAlt, FaPlusCircle } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faExclamationTriangle, faFileDownload, faFileInvoice, faFileInvoiceDollar, faFileSignature, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import config from "../../helpers/config";
import AdminNav from "../../components/VerticalLayout/AdminNav";

const MySwal = withReactContent(Swal);

const Portail = () => {
  const { t } = useTranslation("translation");
  const { documentType, genre, situation, typeHandi, diplomes, connaiss } =
    useSelector((state) => state.data);
  const [docType, setDocType] = useState(null);
  const { isFetching } = useSelector((state) => state.inscription);
  const [formState, setForm] = useState(null);
  const [photoDocument, setPhotoDocument] = useState(null);
  const [openDropdown, setOpenDR] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { numero } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleBackNavigation = () => {
    navigate(-1); // Navigates to the previous page
  };

  const hasRole = (role) => {
    const authUser = localStorage.getItem("authUser");
    const parsedAuthUser = JSON.parse(authUser);
    const decoded = jwtDecode(parsedAuthUser?.accessToken);
    const roles = decoded?.roles ?? [];
    console.log(roles);
    const found = [role]?.some((r) => roles?.indexOf(r) >= 0);
    return found;
  };

  const isCandidat = hasRole("ROLE_CANDIDAT");
  const isAdmin = hasRole("ROLE_ADMIN") || hasRole("ROLE_SUPER_ADMIN");

  const handleDownloadManuscrite = () => {
    let payload = {
      onSuccess: (response) => {
        const linkSource = `data:application/pdf;base64,${response}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "Manuscrite.pdf";
        downloadLink.click();
      },
    };

    dispatch(inscriptionActions.downloadManuscrite(payload));
  }

  useEffect(() => {
    dispatch(dataActions.loadData());
    setLoading(true);
    setTimeout(() => {
      loadInscription(numero);
      setLoading(false);
    }, 1500);
  }, []);

  const refresh = () => {
    loadInscription(numero);
  };

  const loadInscription = (num) => {
    let payload = {
      numero: num,
      onSuccess: (data) => {
        setForm({ ...data });
        const photoDoc =
          data?.documents ?? [].find((doc) => doc.type === "PHOTO");
        setPhotoDocument(photoDoc);
        setTimeout(() => {
          const selectedTypes = data?.typeConnaissance
            ? data?.typeConnaissance.split(",")
            : [];
          console.log(selectedTypes);
          selectedTypes.forEach((type) => {
            setFieldValue(`typeConnaissance.${type}`, true);
          });
        }, 1500);
      },
    };
    dispatch(inscriptionActions.findByNumero(payload));
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const columns = [
    {
      Header: t("inscription.documentType"),
      accessor: (cellProps) => {
        const data =
          documentType.find((dt) => dt.code === cellProps.type)?.description ??
          "Reçu de paiement";
        return data;
      },
      disableFilters: true,
      filterable: false,
    },
    {
      Header: t("inscription.filename"),
      accessor: "filenameUser",
      disableFilters: true,
      filterable: false,
    },

    {
      Header: t("actions.title"),
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            <Link
              className="me-3 text-primary"
              onClick={() => {
                let payload = {
                  filename: cellProps.filename,
                  onSuccess: (response) => {
                    if (cellProps?.fileType === "application/pdf") {
                      const linkSource = `data:application/pdf;base64,${response}`;
                      const downloadLink = document.createElement("a");
                      downloadLink.href = linkSource;
                      downloadLink.download = cellProps?.filenameUser;
                      downloadLink.click();
                    } else {
                      var blob = new Blob(
                        [
                          Uint8Array.from(atob(response), (c) =>
                            c.charCodeAt(0)
                          ),
                        ],
                        { type: "application/octet-stream" }
                      );
                      var url = window.URL.createObjectURL(blob);
                      var a = document.createElement("a");
                      a.href = url;
                      a.download = cellProps.filenameUser;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  },
                };

                dispatch(inscriptionActions.downloadFile(payload));
              }}
            >
              <i className="ri-file-download-line"></i>
            </Link>
            <Link
              className="me-3 text-primary"
              onClick={() => removeDoc(cellProps)}
            >
              <i className="ri-delete-bin-2-line"></i>
            </Link>
          </React.Fragment>
        );
      },
      disableFilters: true,
      filterable: false,
    },
  ];

  const formik = useFormik({
    initialValues: {
      ...formState,
      datenaiss: formState?.datenaiss
        ? format(
          parse(formState?.datenaiss, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )
        : "", // Convert backend format to browser-compatible format
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log(values);
      const typeConnaissanceString = values?.typeConnaissance
        ? Object.keys(values.typeConnaissance)
          .filter((key) => values.typeConnaissance[key])
          .join(",")
        : null;
      const modifiedValues = {
        ...values,
        typeConnaissance: typeConnaissanceString,
        datenaiss: format(new Date(values.datenaiss), "dd/MM/yyyy"),
      };
      console.log("modifiedValues", modifiedValues);
      let payload = {
        data: modifiedValues,
        onSuccess: (data) => {
          refresh();
          Swal.fire({
            icon: "success",
            title: "Modification réussie",
            text: "Les données ont été modifiées avec succès.",
            confirmButtonText: "OK",
          });
        },
      };

      dispatch(inscriptionActions.update(payload));
    },
  });
  console.log("Formik values:", formik.values);
  console.log("Formik isValid:", formik.isValid);

  const { setFieldValue, getFieldProps, values } = formik;

  useEffect(() => { }, [values.documents]);

  const handleSelectedFile = (event) => {
    const files = event.target.files;
    console.log("file", files[0]);
    let doc = {
      id: Math.floor(Math.random() * (600 - 99 + 1)) + 99,
      file: files[0],
      documentType: docType,
    };

    uploadDoc(doc);
  };

  const handleAddFile = (doc) => {
    setFieldValue("documents", [...values.documents, doc]);
    refresh();
  };
  const handleRemoveFile = (doc) => {
    const updated = values.documents.filter((d) => d.id !== doc.id);
    setFieldValue("documents", [...updated]);
  };

  const removeDoc = (doc) => {
    let payload = {
      id: doc.id,
      onSuccess: () => {
        handleRemoveFile(doc);
      },
      onError: (error) => {
        console.log(error);
      },
    };
    dispatch(inscriptionActions.deleteFile(payload));
  };
  const uploadDoc = (doc) => {
    const query = new FormData();
    query.append("file", doc.file);
    query.append("inscriptionId", formState.id);
    query.append("documentType", doc.documentType);
    let payload = {
      formData: query,
      onSuccess: (data) => {
        handleAddFile(data);
        toggleModal();
      },
      onError: (error) => {
        console.log(error);
      },
    };
    dispatch(inscriptionActions.uploadFile(payload));
  };

  const getStripeFraisClass = () => {
    switch (values?.paiement) {
      case "EN_ATTENTE":
        return "status-frais-stripe status-frais-pending";
      case "NON_PAYE":
        return "status-frais-stripe status-frais-failed";
      case "PAYE":
        return "status-frais-stripe status-frais-successful";
      case null:
        return "status-certificat-stripe status-frais-failed";
    }
  };

  const getStripeCertificatClass = () => {
    switch (values?.paiementCertificat) {
      case "EN_ATTENTE":
        return "status-certificat-stripe status-certificat-pending";
      case "NON_PAYE":
        return "status-certificat-stripe status-certificat-failed";
      case "PAYE":
        return "status-certificat-stripe status-certificat-successful";
      case null:
        return "status-certificat-stripe status-certificat-failed";
    }
  };

  // Payment status badge functions
  const getFraisBadgeColor = () => {
    switch (values?.paiement) {
      case "EN_ATTENTE":
        return "warning";
      case "NON_PAYE":
        return "danger";
      case "PAYE":
        return "success";
      default:
        return "secondary";
    }
  };

  const getCertificatBadgeColor = () => {
    switch (values?.paiementCertificat) {
      case "EN_ATTENTE":
        return "warning";
      case "NON_PAYE":
        return "danger";
      case "PAYE":
        return "success";
      default:
        return "secondary";
    }
  };


  const getStripeFraisLabel = () => {
    switch (values?.paiement) {
      case "EN_ATTENTE":
        return "EN ATTENTE PAIEMENT (Frais Condidature)";
      case "NON_PAYE":
        return "Non payé (Frais Condidature)";
      case "PAYE":
        return "PAIEMENT ÉFFECTUÉ (Frais Condidature)";
      case null:
        return "Non payé (Frais Condidature)";
    }
  };
  const getStripeCertificatLabel = () => {
    switch (values?.paiementCertificat) {
      case "EN_ATTENTE":
        return "EN ATTENTE PAIEMENT (Frais Certificat)";
      case "NON_PAYE":
        return "Non payé (Frais Certificat)";
      case "PAYE":
        return "PAIEMENT ÉFFECTUÉ (Frais Certificat)";
      case null:
        return "Non payé (Frais Certificat)";
    }
  };

  const handleCandidatureRetenueClick = () => {
    updateStatus("CANDIDATURE_RETENUE");
  };

  const handleNonRetenueClick = () => {
    updateStatus("CANDIDATURE_NON_RETENUE");
  };

  const handleAdmisClick = () => {

    MySwal.fire({
      title: t("message.confirm"),
      showCancelButton: true,
      confirmButtonText: t("actions.confirm"),
      cancelButtonText: t("actions.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus("CANDIDAT_ADMIS");
      } else if (result.isDismissed) {
      }
    });
  };

  const handleNonAdmisClick = () => {
    MySwal.fire({
      title: t("message.confirm"),
      showCancelButton: true,
      confirmButtonText: t("actions.confirm"),
      cancelButtonText: t("actions.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus("CANDIDAT_NON_ADMIS");
      } else if (result.isDismissed) {
      }
    });
  };

  const updateStatus = (inscriptionStatus) => {
    console.log(inscriptionStatus);
    const updatedValues = { ...formState, inscriptionStatus };
    console.log("updatedValues", updatedValues);
    let payload = {
      data: updatedValues,
      onSuccess: (data) => {
        setForm({ ...data });
        refresh();
        Swal.fire(t("message.confirmSuccess"), "", "success");
      },
    };

    dispatch(inscriptionActions.update(payload));
  };

  const handleDownloadReportPaiment = (id) => {
    let payload = {
      id,
      typePaiment: "FRAIS_CANDIDATURE",
      onSuccess: (data) => {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: "application/pdf;base64" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      onError: () => {
        console.error("Error downloading payment report");
      },
    };
    dispatch(inscriptionActions.downloadReportPayment(payload));
  };
  const handleDownloadReportPaimentCertificat = (id) => {
    let payload = {
      id,
      typePaiment: "FRAIS_CERTIFICAT",
      onSuccess: (data) => {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: "application/pdf;base64" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      onError: () => {
        console.error("Error downloading payment report");
      },
    };
    dispatch(inscriptionActions.downloadReportPayment(payload));
  };
  const handleDownloadReportInscription = (id) => {
    let payload = {
      id,
      onSuccess: (data) => {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: "application/pdf;base64" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      onError: () => {
        console.error("Error downloading payment report");
      },
    };
    dispatch(inscriptionActions.downloadReportInscription(payload));
  };

  return (
    <React.Fragment>
      {isFetching || loading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid={true}>
          {isAdmin ? <AdminNav /> : null}

            <AvForm
              className="needs-validation"
              onValidSubmit={formik.handleSubmit}
            >

              <Card className="mb-4">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs={3}>
                      {isCandidat ? (
                        <h5 className="mb-0 d-flex align-items-center"><i className="ri-file-list-3-line me-2"></i>{values.numero}</h5>
                      ) : (
                        <h5
                          className="mb-0 d-flex align-items-center"
                          onClick={handleBackNavigation}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-arrow-go-back-line"></i>{" "}
                          {values.numero}
                        </h5>
                      )}
                    </Col>
                    <Col xs={9}>
                      <div className="d-flex justify-content-end align-items-center status">
                        <div className="progress-indicator d-flex align-items-center">
                          <div
                            className={`step ${formState?.inscriptionStatus === "NOUVEAU"
                              ? "active"
                              : ""
                              }`}
                          >
                            NOUVEAU
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${formState?.inscriptionStatus ===
                              "EN_COURS_ANALYSE"
                              ? "active"
                              : ""
                              }`}
                          >
                            EN COURS D'ANALYSE
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${formState?.inscriptionStatus ===
                              "CANDIDATURE_RETENUE"
                              ? "active"
                              : ""
                              }`}
                          >
                            CANDIDATURE RETENUE
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${formState?.inscriptionStatus ===
                              "CANDIDATURE_NON_RETENUE"
                              ? "active"
                              : ""
                              }`}
                          >
                            CANDIDATURE NON RETENUE
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${formState?.inscriptionStatus === "CANDIDAT_ADMIS"
                              ? "active"
                              : ""
                              }`}
                          >
                            CANDIDAT ADMIS
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${formState?.inscriptionStatus ===
                              "CANDIDAT_NON_ADMIS"
                              ? "active"
                              : ""
                              }`}
                          >
                            CANDIDAT NON ADMIS
                          </div>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>


                  </Row>
                </CardBody>
              </Card>
              {isCandidat && (
                <>
                  <Alert color="info" className="mb-4">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faInfoCircle} className="me-2" size="lg" />
                      <div>
                        <h5 className="mb-1">Information importante</h5>
                        <p className="mb-0">
                          Si vous constatez que votre compte MTN MONEY a été débité et que le statut de votre transaction n'est pas 'Payé' sous 24h, veuillez nous contacter au 0596551414/0710821768 avec les détails de la transaction pour une assistance rapide.
                        </p>
                      </div>
                    </div>
                  </Alert>

                  <Alert color="info" className="mb-4">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faCalendarAlt} className="me-2" size="lg" />
                      <div>
                        <h5 className="mb-1">Calendrier des visites médicales</h5>
                        <p className="mb-2">
                          La visite médicale se tiendra à l'Institut National Ivoirien pour la Promotion des Aveugles (INIPA) à Yopougon, selon le calendrier suivant :
                        </p>
                        <ul className="mb-2 ps-3">
                          <li>Lundi 27 janvier 2025 : Non voyantes, Malvoyantes, Albinos, Handicapées psychiques</li>
                          <li>Du mardi 28 janvier au samedi 1er février 2025 : Handicapés physiques, Personnes de petite taille</li>
                          <li>Samedi 1er février 2025 : Sourds, Malentendants, Bègues</li>
                        </ul>
                        <p className="mb-0 fst-italic">
                          N'hésitez pas à partager cette information avec toutes les personnes concernées !
                        </p>
                        <p className="mb-0 fw-bold">
                          NB: Téléchargez la lettre manuscrite sur le portail web, puis remplissez-la et signez. Remettez-la aux agents recruteurs lors de la visite médicale.
                        </p>
                      </div>
                    </div>
                  </Alert>
                  <Alert color="danger" className="mb-4">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" size="lg" />
                      <div>
                        <h5 className="mb-2">URGENT - Vérification des documents</h5>
                        <p className="mb-3 fw-bold">
                          Les candidats sont invités à vérifier les dossiers téléchargés dans leurs espace candidat au plus tard le <span className="text-decoration-underline">lundi 20 Janvier 2025 à 23h59</span>.
                          Tout document incomplet et non valide entraînera une non admissibilité à la visite médicale.
                        </p>
                        <p className="mb-2">Les documents concernés :</p>
                        <ul className="mb-0 ps-3">
                          <li>Extraits de naissance</li>
                          <li>Certificat de nationalité moins de 10 ans</li>
                          <li>CNI</li>
                          <li>Certificat attestant son handicap</li>
                          <li>Diplôme légalisé ou authentifié</li>
                          <li>Demande manuscrite</li>
                          <li>Casier judiciaire moins de 03 mois</li>
                          <li>CV certifié sincère</li>
                        </ul>
                      </div>
                    </div>
                  </Alert>
                </>
              )}
              <Card className="payment-block">
                <Card className="mb-4">
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs={3}>
                        <h5 className="mb-0">{t("section.fiche")}</h5>
                      </Col>
                      <Col xs={9} className="text-end">
                        <Button
                          color="primary"
                          className="me-2"
                          onClick={() => handleDownloadReportInscription(formik.values.id)}
                        >
                          <FontAwesomeIcon icon={faFileDownload} className="me-2" />
                          Fiche d'inscription
                        </Button>

                        {formState?.paiement === "PAYE" && (
                          <Button
                            color="primary"
                            className="me-2"
                            onClick={() => handleDownloadReportPaiment(formState.id)}
                          >
                            <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
                            Reçu de paiement
                          </Button>
                        )}

                        {formState?.paiementCertificat === "PAYE" && (
                          <Button
                            color="primary"
                            className="me-2"
                            onClick={() => handleDownloadReportPaimentCertificat(formState.id)}
                          >
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />
                            Reçu de certificat
                          </Button>
                        )}

                        <Button
                          color="primary"
                          onClick={() => handleDownloadManuscrite()}
                        >
                          <FontAwesomeIcon icon={faFileSignature} className="me-2" />
                          Demande manuscrite
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                <CardBody>
                  <Row className="mb-1">
                    <Col xs={12}>
                      {isAdmin && (
                        <ButtonGroup>
                          {formState?.inscriptionStatus ===
                            "EN_COURS_ANALYSE" && (
                              <>
                                <Button onClick={handleCandidatureRetenueClick}>
                                  Retenue
                                </Button>
                                <Button
                                  className="ms-1"
                                  onClick={handleNonRetenueClick}
                                >
                                  Non Retenue
                                </Button>
                              </>
                            )}
                          {formState?.inscriptionStatus ===
                            "CANDIDATURE_RETENUE" && (
                              <>
                                <Button onClick={handleAdmisClick}>
                                  Admis
                                </Button>
                                <Button
                                  className="ms-1"
                                  onClick={handleNonAdmisClick}
                                >
                                  Non Admis
                                </Button>
                              </>
                            )}
                        </ButtonGroup>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs={12}>
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="me-4">
                          <small className="text-muted d-block mb-1">Frais d'inscription</small>
                          <Badge
                            color={getFraisBadgeColor()}
                            className="px-3 py-2"
                            title="Status du paiement des frais d'inscription"
                          >
                            <i className={`ri-money-dollar-circle-line me-1`}></i>
                            {getStripeFraisLabel()}
                          </Badge>
                        </div>
                        <div>
                          <small className="text-muted d-block mb-1">Certificat</small>
                          <Badge
                            color={getCertificatBadgeColor()}
                            className="px-3 py-2"
                            title="Status du paiement du certificat"
                          >
                            <i className={`ri-file-certificate-line me-1`}></i>
                            {getStripeCertificatLabel()}
                          </Badge>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Container fluid={true}>
                        <Row>
                          <Col lg="3">
                            <Card className="mb-4">
                              <CardBody className="text-center p-4">
                                {values.imageServerUrl ? (
                                  <img
                                    src={`${config.baseURL}${values.imageServerUrl}`}
                                    alt="Profile"
                                    className="rounded"
                                    style={{
                                      width: '250px',
                                      height: '250px',
                                      objectFit: 'cover',
                                      border: '1px solid #dee2e6',
                                      boxShadow: '0 0 10px rgba(0,0,0,0.1)'
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="rounded bg-light d-flex align-items-center justify-content-center"
                                    style={{
                                      width: '250px',
                                      height: '250px',
                                      margin: '0 auto',
                                      border: '1px solid #dee2e6',
                                      boxShadow: '0 0 10px rgba(0,0,0,0.1)'
                                    }}
                                  >
                                    <i className="ri-user-3-line" style={{ fontSize: '5rem' }}></i>
                                  </div>
                                )}
                                <h5 className="mt-4 mb-0">{values.nom} {values.prenoms}</h5>
                                <p className="text-muted mb-0">{values.numero}</p>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg="9">
                            <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.nom")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("nom")}
                                    placeholder={t("inscription.nom")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={
                                      isAdmin
                                        ? {} // Skip validation if the role is admin
                                        : { required: { value: false } } // Apply validation for other roles
                                    }
                                    id="code"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.prenom")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("prenom")}
                                    placeholder={t("inscription.prenom")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={
                                      isAdmin
                                        ? {} // Skip validation if the role is admin
                                        : { required: { value: false } } // Apply validation for other roles
                                    }
                                    id="prenom"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="pere">
                                    {t("inscription.pere")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("pere")}
                                    placeholder={t("inscription.pere")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="pere"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="mere">
                                    {t("inscription.mere")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("mere")}
                                    placeholder={t("inscription.mere")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="permeree"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.lieunais")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("lieunais")}
                                    placeholder={t("inscription.lieunais")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="lieunais"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.datenaiss")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("datenaiss")}
                                    placeholder={t("inscription.datenaiss")}
                                    type="date"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="datenaiss"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.nationalite")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("nationalite")}
                                    placeholder={t("inscription.nationalite")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="nationalite"
                                  />
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="genre">
                                    {t("inscription.genre")}
                                  </Label>
                                  <AvField
                                    type="select"
                                    className="form-control"
                                    {...getFieldProps("genre")}
                                    //onChange={handleChangeNationalite}
                                    validate={{ required: { value: false } }}
                                    id="genre"
                                  >
                                    <option value="">{t("Sélectionner...")}</option>
                                    {genre.map((g, index) => (
                                      <option key={index} value={g.code}>
                                        {g.description}
                                      </option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.numCmu")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("numCmu")}
                                    placeholder={t("inscription.numCmu")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="numCmu"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="numCNI">
                                    {t("inscription.numCNI")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("numCNI")}
                                    placeholder={t("inscription.numCNI")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="numCNI"
                                  />
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="situation">
                                    {t("inscription.situation")}
                                  </Label>
                                  <AvField
                                    type="select"
                                    className="form-control"
                                    {...getFieldProps("situation")}
                                    //onChange={handleChangeNationalite}
                                    validate={{ required: { value: false } }}
                                    id="situation"
                                  >
                                    <option value="">{t("Sélectionner...")}</option>
                                    {situation.map((g, index) => (
                                      <option key={index} value={g.code}>
                                        {g.description}
                                      </option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="typeHandi">
                                    {t("inscription.typeHandi")}
                                  </Label>
                                  <AvField
                                    type="select"
                                    className="form-control"
                                    {...getFieldProps("typeHandi")}
                                    //onChange={handleChangeNationalite}
                                    validate={{ required: { value: false } }}
                                    id="typeHandi"
                                  >
                                    <option value="">{t("Sélectionner...")}</option>
                                    {typeHandi.map((g, index) => (
                                      <option key={index} value={g.code}>
                                        {g.description}
                                      </option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autreTypeHandicap"
                                  >
                                    {t("inscription.autreTypeHandicap")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("autreTypeHandicap")}
                                    placeholder={t("inscription.autreTypeHandicap")}
                                    type="text"
                                    className="form-control"
                                    id="autreTypeHandicap"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.telephone")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("telephone")}
                                    placeholder={t("inscription.telephone")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{
                                      required: { value: false },
                                      maxLength: {
                                        value: 10,
                                        errorMessage: t("message.phoneExceed"),
                                      },
                                    }}
                                    id="telephone"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <AvField
                                    placeholder={t("inscription.email")}
                                    errorMessage={t("message.emailInvalid")}
                                    {...getFieldProps("email")}
                                    label={t("inscription.email")}
                                    type="email"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.lieuResidence")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("lieuResidence")}
                                    placeholder={t("inscription.lieuResidence")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="lieuResidence"
                                  />
                                </div>
                              </Col>

                              <Col lg="4">
                                <div className="mb-3">
                                  <AvField
                                    placeholder={t("inscription.niveauEtude")}
                                    {...getFieldProps("niveauEtude")}
                                    label={t("inscription.niveauEtude")}
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="typeDiplome"
                                  >
                                    {t("inscription.typeDiplome")}
                                  </Label>
                                  <AvField
                                    type="select"
                                    className="form-control"
                                    {...getFieldProps("typeDiplome")}
                                    //onChange={handleChangeNationalite}
                                    validate={{ required: { value: false } }}
                                    id="typeDiplome"
                                  >
                                    <option value="">{t("Sélectionner...")}</option>
                                    {diplomes.map((g, index) => (
                                      <option key={index} value={g.code}>
                                        {g.description}
                                      </option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="code">
                                    {t("inscription.diplomeAutre")}
                                  </Label>
                                  <AvField
                                    {...getFieldProps("diplomeAutre")}
                                    placeholder={t("inscription.diplomeAutre")}
                                    type="text"
                                    errorMessage={t("message.required")}
                                    className="form-control"
                                    id="diplomeAutre"
                                  />
                                </div>
                              </Col>
                              <Col md="8">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="typeConnaissance"
                                  >
                                    {t("inscription.typeConnaissance")}
                                  </Label>
                                  <div
                                    id="typeConnaissance"
                                    className="form-control"
                                  >
                                    {connaiss.map((g, index) => (
                                      <div
                                        key={index}
                                        className="form-check form-check-inline me-5"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`typeConnaissance_${index}`}
                                          value={g.code}
                                          checked={
                                            getFieldProps(
                                              `typeConnaissance.${g.code}`
                                            ).value
                                          }
                                          onChange={() => {
                                            setFieldValue(
                                              `typeConnaissance.${g.code}`,
                                              !getFieldProps(
                                                `typeConnaissance.${g.code}`
                                              ).value
                                            );
                                          }}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor={`typeConnaissance_${index}`}
                                        >
                                          {g.description}
                                        </Label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                        {isCandidat && (
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label className="form-label" htmlFor="code">
                                  {t("inscription.login")}
                                </Label>
                                <AvField
                                  {...getFieldProps("login")}
                                  placeholder={t("inscription.login")}
                                  type="text"
                                  errorMessage={t("message.required")}
                                  className="form-control"
                                  validate={{
                                    required: { value: false },
                                    maxLength: {
                                      value: 10,
                                      errorMessage: t("message.phoneExceed"),
                                    },
                                  }}
                                  id="login"
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label className="form-label" htmlFor="code">
                                  {t("inscription.password")}
                                </Label>
                                <AvField
                                  {...getFieldProps("password")}
                                  placeholder={t("inscription.password")}
                                  type="password"
                                  errorMessage={t("message.required")}
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="password"
                                />
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Button
                          color="primary"
                          type="submit"
                        // disabled={
                        //   isCandidat &&
                        //   [
                        //     "EN_COURS_ANALYSE",
                        //     "CANDIDATURE_RETENUE",
                        //     "CANDIDATURE_NON_RETENUE",
                        //     "CANDIDAT_ADMIS",
                        //     "CANDIDAT_NON_ADMIS",
                        //   ].includes(formState?.inscriptionStatus)
                        // }
                        >
                          {t("actions.save")}
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="card-title m-0">
                          {t("section.documents")}
                        </h4>

                        <Button
                          color="success"
                          size="sm"
                          onClick={() => toggleModal()}
                          title={t("DeleteAction")}
                        >
                          Télécharger un fichier
                        </Button>
                      </div>

                      <TableContainer
                        columns={columns || []}
                        data={values.documents ?? []}
                        isPagination={false}
                        isAddParamList={true}
                        customPageSizeOptions={true}
                        iscustomPageSize={false}
                        isBordered={false}
                        customPageSize={10}
                        canDownloadtemp={true}
                        isGlobalFilter={false}
                        className="table-primary"
                      />
                    </CardBody>
                  </Card>
                </Col>

                <Modal isOpen={modalOpen} toggle={toggleModal} centered>
                  <ModalHeader
                    toggle={toggleModal}
                    className="border-0"
                  ></ModalHeader>
                  <ModalBody className="pt-0">
                    <Row className="mb-2 align-items-center">
                      <Col md="2"></Col>
                      <Col md="8">
                        <AvField
                          type="select"
                          className="form-control"
                          name="docType"
                          onChange={(e) => setDocType(e.target.value || "")}
                        >
                          <option value="">{t("Type du document...")}</option>
                          {documentType.map((g, index) => (
                            <option key={index} value={g.code}>
                              {g.description}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                    </Row>
                    <Row className=" mb-4 align-items-center">
                      <Col md="2"></Col>
                      <Col md="8">
                        <input
                          type="file"
                          onChange={handleSelectedFile}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </Row>

              {!isCandidat &&
                [
                  "EN_COURS_ANALYSE",
                  "CANDIDATURE_RETENUE",
                  "CANDIDAT_ADMIS",
                  "CANDIDAT_NON_ADMIS",
                ].includes(formState?.inscriptionStatus) && (
                  <Row>
                    <Card>
                      <CardBody>
                        <Col xs={2}>
                          <h4 className="card-title">{t("section.avis")}</h4>
                          <AvField
                            {...getFieldProps("note")}
                            label={t("inscription.note")}
                            type="number"
                            min="0"
                            max="99" // Ensures the value cannot exceed 99 when submitted
                            value="0" // Default value is set to 0
                            onInput={(e) => {
                              if (e.target.value > 99) e.target.value = 99; // Prevent typing values above 99
                              if (e.target.value < 0) e.target.value = 0; // Prevent typing values below 0
                            }}
                            disabled={
                              formState?.inscriptionStatus === "CANDIDAT_ADMIS" ||
                              formState?.inscriptionStatus === "CANDIDAT_NON_ADMIS"
                            }
                          />
                        </Col>
                        <Col xs={12}>
                          <AvField
                            {...getFieldProps("comment")}
                            label={t("inscription.comment")}
                            type="textarea"
                            rows="5"
                            disabled={
                              formState?.inscriptionStatus ===
                              "CANDIDAT_ADMIS" ||
                              formState?.inscriptionStatus ===
                              "CANDIDAT_NON_ADMIS"
                            }
                          />
                        </Col>
                        <Button
                          className="ms-1"
                          disabled={
                            formState?.inscriptionStatus ===
                            "CANDIDAT_ADMIS" ||
                            formState?.inscriptionStatus ===
                            "CANDIDAT_NON_ADMIS"
                          }
                          onClick={() => formik.handleSubmit()}
                        >
                          {t("actions.save")}
                        </Button>
                      </CardBody>
                    </Card>
                  </Row>
                )}
            </AvForm>
            {isCandidat && (
              <Row>
                <Col xs={12}>
                  <PaiementBloc formData={formState} onUpdate={refresh} />
                </Col>
                <Col xs={12}>
                  <PaimentCertBloc formData={formState} onUpdate={refresh} />
                </Col>
              </Row>
            )}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Portail;
