import instance from "../helpers/AxiosInterceptor";

export const search = (query) => {
  console.log(query);
  return instance.post(`/transaction/search`, JSON.stringify(query));
};

export const generateExcelTransaction = (query) => {
  console.log("excel query", query);
  return instance.post(
    `/transaction/generate/excelTransaction`,
    JSON.stringify(query),
    {
      responseType: "blob",
    }
  );
};

export const loadFailedTransactions = (query, page = 0, size = 10) => {
  return instance.post(`/transaction/loadFailedTx?page=${page}&size=${size}`, JSON.stringify(query));
};

export const synchData = (query) => {
  return instance.post(`/transaction/syncData`, JSON.stringify(query));
};

