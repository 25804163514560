import { AvField } from "availity-reactstrap-validation";
import { FormikProvider } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Container, Label, Row } from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"; // Import only the info icon
import "../../assets/css/style.css";
const IdentificationTab = ({ formik }) => {
  const { t } = useTranslation("translation");
  const { getFieldProps } = formik;
  const { genre, situation, typeHandi, diplomes, connaiss } = useSelector(
    (state) => state.data
  );
  const [showLoginHint, setShowLoginHint] = useState(false);
  const [showPasswordHint, setShowPasswordHint] = useState(false);

  const handleCheckboxChange = (code) => {
    const selectedOptions = formik.values.typeConnaissance
      ? formik.values.typeConnaissance.split(",")
      : [];

    if (selectedOptions.includes(code)) {
      // Remove if already selected
      const updatedOptions = selectedOptions.filter((item) => item !== code);
      formik.setFieldValue("typeConnaissance", updatedOptions.join(","));
    } else {
      // Add if not selected
      selectedOptions.push(code);
      formik.setFieldValue("typeConnaissance", selectedOptions.join(","));
    }
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.nom")}
              </Label>
              <AvField
                {...getFieldProps("nom")}
                placeholder={t("inscription.nom")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="code"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.prenom")}
              </Label>
              <AvField
                {...getFieldProps("prenom")}
                placeholder={t("inscription.prenom")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="prenom"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="pere">
                {t("inscription.pere")}
              </Label>
              <AvField
                {...getFieldProps("pere")}
                placeholder={t("inscription.pere")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="pere"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="mere">
                {t("inscription.mere")}
              </Label>
              <AvField
                {...getFieldProps("mere")}
                placeholder={t("inscription.mere")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="permeree"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.lieunais")}
              </Label>
              <AvField
                {...getFieldProps("lieunais")}
                placeholder={t("inscription.lieunais")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="lieunais"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.datenaiss")}
              </Label>
              <AvField
                {...getFieldProps("datenaiss")}
                placeholder={t("inscription.datenaiss")}
                type="date"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="datenaiss"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.nationalite")}
              </Label>
              <AvField
                {...getFieldProps("nationalite")}
                placeholder={t("inscription.nationalite")}
                type="text"
                value="IVORIENNE"
                className="form-control"
                id="nationalite"
                disabled
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="genre">
                {t("inscription.genre")}
              </Label>
              <AvField
                type="select"
                className="form-control"
                {...getFieldProps("genre")}
                //onChange={handleChangeNationalite}
                validate={{ required: { value: false } }}
                id="genre"
              >
                <option value="">{t("Sélectionner...")}</option>
                {genre.map((g, index) => (
                  <option key={index} value={g.code}>
                    {g.description}
                  </option>
                ))}
              </AvField>
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.numCmu")}
              </Label>
              <AvField
                {...getFieldProps("numCmu")}
                placeholder={t("inscription.numCmu")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="numCmu"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="numCNI">
                {t("inscription.numCNI")}
              </Label>
              <AvField
                {...getFieldProps("numCNI")}
                placeholder={t("inscription.numCNI")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="numCNI"
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="situation">
                {t("inscription.situation")}
              </Label>
              <AvField
                type="select"
                className="form-control"
                {...getFieldProps("situation")}
                //onChange={handleChangeNationalite}
                validate={{ required: { value: false } }}
                id="situation"
              >
                <option value="">{t("Sélectionner...")}</option>
                {situation.map((g, index) => (
                  <option key={index} value={g.code}>
                    {g.description}
                  </option>
                ))}
              </AvField>
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="typeHandi">
                {t("inscription.typeHandi")}
              </Label>
              <AvField
                type="select"
                className="form-control"
                {...getFieldProps("typeHandi")}
                //onChange={handleChangeNationalite}
                validate={{ required: { value: false } }}
                id="typeHandi"
              >
                <option value="">{t("Sélectionner...")}</option>
                {typeHandi.map((g, index) => (
                  <option key={index} value={g.code}>
                    {g.description}
                  </option>
                ))}
              </AvField>
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="autreTypeHandicap">
                {t("inscription.autreTypeHandicap")}
              </Label>
              <AvField
                {...getFieldProps("autreTypeHandicap")}
                placeholder={t("inscription.autreTypeHandicap")}
                type="text"
                className="form-control"
                id="autreTypeHandicap"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.telephone")}
              </Label>
              <AvField
                {...getFieldProps("telephone")}
                placeholder={t("inscription.telephone")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{
                  required: { value: true },
                  maxLength: {
                    value: 10,
                    errorMessage: t("message.phoneExceed"),
                  },
                }}
                id="telephone"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <AvField
                placeholder={t("inscription.email")}
                errorMessage={t("message.emailInvalid")}
                {...getFieldProps("email")}
                label={t("inscription.email")}
                type="email"
              />
            </div>
          </Col>

          <Col lg="3">
            <div className="mb-3">
              <AvField
                placeholder={t("inscription.niveauEtude")}
                {...getFieldProps("niveauEtude")}
                label={t("inscription.niveauEtude")}
                type="text"
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="typeDiplome">
                {t("inscription.typeDiplome")}
              </Label>
              <AvField
                type="select"
                className="form-control"
                {...getFieldProps("typeDiplome")}
                //onChange={handleChangeNationalite}
                validate={{ required: { value: false } }}
                id="typeDiplome"
              >
                <option value="">{t("Sélectionner...")}</option>
                {diplomes.map((g, index) => (
                  <option key={index} value={g.code}>
                    {g.description}
                  </option>
                ))}
              </AvField>
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.diplomeAutre")}
              </Label>
              <AvField
                {...getFieldProps("diplomeAutre")}
                placeholder={t("inscription.diplomeAutre")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                id="diplomeAutre"
                disabled={formik.values?.typeDiplome !== "AUTRE"}
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="typeConnaissance">
                {t("inscription.typeConnaissance")}
              </Label>
              <div id="typeConnaissance" className="form-control">
                {connaiss.map((g, index) => (
                  <div
                    key={index}
                    className="form-check form-check-inline me-4"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`typeConnaissance_${index}`}
                      value={g.code}
                      checked={(formik.values.typeConnaissance || "")
                        .split(",")
                        .includes(g.code)}
                      onChange={() => handleCheckboxChange(g.code)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor={`typeConnaissance_${index}`}
                    >
                      {g.description}
                    </Label>
                  </div>
                ))}
              </div>
            </div>
          </Col>

          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label" htmlFor="code">
                {t("inscription.lieuResidence")}
              </Label>
              <AvField
                {...getFieldProps("lieuResidence")}
                placeholder={t("inscription.lieuResidence")}
                type="text"
                errorMessage={t("message.required")}
                className="form-control"
                validate={{ required: { value: true } }}
                id="lieuResidence"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3 position-relative">
              <Label className="form-label " htmlFor="login">
                {t("inscription.login")} {/* Adjust label text */}
              </Label>
              <span
                className="m-3"
                onMouseEnter={() => setShowLoginHint(true)}
                onMouseLeave={() => setShowLoginHint(false)}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {showLoginHint && (
                <div
                  className="password-hint position-absolute bg-light border rounded shadow p-2"
                  style={{ top: "30px", zIndex: 1000 }}
                >
                  <strong>Exigences du numéro de téléphone :</strong>
                  <ul className="mb-0">
                    <li>Doit contenir 10 chiffres</li>
                    <li>Format : 0123456789</li>
                  </ul>
                </div>
              )}
              <AvField
                {...getFieldProps("login")}
                placeholder={t("inscription.login")}
                type="text"
                className="form-control"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("message.required"),
                  },
                  pattern: {
                    value: /^\d{10}$/, // Regex for exactly 10 digits
                    errorMessage: t("message.phoneFormat"), // Adjust error message
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: t("message.phoneExceeded"), // Error message for exceeding 10 digits
                  },
                }}
                id="login"
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3 position-relative">
              <Label className="form-label" htmlFor="password">
                {t("inscription.password")}
              </Label>
              <span
                className="m-3"
                style={{ right: "40px", top: "40%", cursor: "pointer" }}
                onMouseEnter={() => setShowPasswordHint(true)}
                onMouseLeave={() => setShowPasswordHint(false)}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <AvField
                name="password"
                placeholder={t("inscription.password")}
                type="password"
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value)
                }
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("message.required"),
                  },
                  pattern: {
                    value:
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
                    errorMessage: t("message.passwordFormat"),
                  },
                  minLength: { value: 8, errorMessage: t("message.minLength") },
                  maxLength: {
                    value: 16,
                    errorMessage: t("message.maxLength"),
                  },
                }}
                className="form-control"
                errorMessage={t("message.password")}
                id="password"
              />

              {showPasswordHint && (
                <div
                  className="password-hint position-absolute bg-light border rounded shadow p-2"
                  style={{ top: "30px", zIndex: 1000 }}
                >
                  <strong>Le mot de passe doit contenir :</strong>
                  <ul className="mb-0">
                    <li>Au moins 8 caractères</li>
                    <li>Au moins une lettre majuscule</li>
                    <li>Au moins une lettre minuscule</li>
                    <li>Au moins 1 chiffre</li>
                    <li>Au moins 1 caractère spécial (@, $, !, *, etc.)</li>
                  </ul>
                  <hr />
                  <strong>Exemple</strong> : Bonjour@2024
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default IdentificationTab;
