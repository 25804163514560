import React, { useMemo, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  Button,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../../components/Common/TableContainer";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "../../assets/css/style.css";
import { FaFilter, FaUndo, FaFileExcel } from "react-icons/fa";
import Swal from "sweetalert2";
import AdminNav from "../../components/VerticalLayout/AdminNav";
import { jwtDecode } from "jwt-decode";

const initQuery = {
  numero: "",
  nom: "",
  prenom: "",
  datenaiss: "",
  lieunais: "",
  genre: "",
  numCmu: "",
  numCNI: "",
  telephone: "",
  email: "",
  typeHandi: "",
  status: "",
  paiement: "",
};
function Transactions() {
  const { t } = useTranslation("translation");
  const {
    isFetching,
    inscriptions,
    error,
    totalElements,
    totalPages,
    pageNumber,
  } = useSelector((state) => state.inscription);
  const [loading, setLoading] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [smsHistoryModal, setSmsHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const filterRefs = useRef({});

  const [criteria, setCriteria] = useState({ ...initQuery });
  const [showFilters, setShowFilters] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageSize = 10; // Set the number of items per page
  const page = pageNumber; // Use the current page from the state

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      dispatch(
        inscriptionActions.search({
          criteria,
          page: page,
          size: pageSize,
        })
      );
    }, 1500);
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      // Ensure not to exceed total pages
      dispatch(
        inscriptionActions.search({
          criteria,
          page: page + 1,
          size: pageSize,
        })
      );
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      // Ensure not to go below the first page
      dispatch(
        inscriptionActions.search({
          criteria,
          page: page - 1,
          size: pageSize,
        })
      );
    }
  };

  const statusStyles = (status, inscription) => {
    return {
      display: "inline-block",
      padding: "5px 10px",
      borderRadius: "8px",
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: "center",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light shadow to give a 3D effect
      margin: "4px 0",
      backgroundColor: inscription
        ? getStatusInscBackgroundColor(status)
        : getStatusBackgroundColor(status),
      color: "white",
    };
  };
  const getStatusText = (status) => {
    if (status === "FRAIS_CERTIFICAT") return "Frais de certificat";
    if (status === "FRAIS_CANDIDATURE") return "Frais de candidature";
    if (status === "PAYE") return "Payé";
    if (status === "NON_PAYE") return "Non payé";
    if (status === null) return "Non payé";
    if (status === "EN_ATTENTE") return "En attente paiement";
    if (status === "SUCCESSFUL") return "Payé";
    if (status === "FAILED") return "Non payé";
    if (status === "PENDING") return "En attente paiement";
  };

  // Helper function to set different background colors for each status
  const getStatusBackgroundColor = (status) => {
    if (status === "EN_ATTENTE") return "#0056b3"; // Blue for Draft
    if (status === "PAYE") return "green"; // Green for Approved
    if (status === "NON_PAYE") return "red"; // Red for Canceled
    if (status === null) return "red";
  };

  const getStatusInscBackgroundColor = (status) => {
    if (status === "NOUVEAU") return "grey"; // Blue for Draft
    if (status === "EN_COURS_ANALYSE") return "orange"; // Green for Approved
    if (status === "CANDIDAT_ADMIS") return "green"; // Red for Canceled
    if (status === "CANDIDAT_NON_ADMIS") return "red"; // Red for Canceled
    if (status === "CANDIDATURE_RETENUE") return "green"; // Red for Canceled
    if (status === "CANDIDATURE_NON_RETENUE") return "red"; // Red for Canceled
  };

  const handleFilterChange = (e) => {
    const { id, value } = e.target;

    setCriteria((prevCriteria) => ({
      ...prevCriteria,
      [id]: value,
    }));

    if (value.length > 3) {
      dispatch(
        inscriptionActions.filter({
          criteria: { ...criteria, [id]: value },
          page: page,
          size: pageSize,
        })
      );
    }
  };

  const toggleFilters = () => setShowFilters((prev) => !prev);

  const columns = useMemo(
    () => [
      {
        Header: t("inscription.numero"),
        accessor: "numero",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                name="numero"
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        accessor: "createdDate",
        Header: `${t("inscription.createdDate")}`,
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                name="createdDate"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.nom"),
        accessor: "nom",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                name="nom"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },

      {
        Header: t("inscription.prenom"),
        accessor: "prenom",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.datenaiss"),
        accessor: "datenaiss",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="date"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.lieunais"),
        accessor: "lieunais",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.genre"),
        accessor: "genre",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="select"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              >
                <option value="">{t("all")}</option>
                <option value="HOMME">{t("homme")}</option>
                <option value="FEMME">{t("femme")}</option>
              </Input>
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.numCmu"),
        accessor: "numCmu",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.numCNI"),
        accessor: "numCNI",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.telephone"),
        accessor: "telephone",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.email"),
        accessor: "email",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              />
            </FormGroup>
          ),
      },
      {
        Header: t("inscription.typeHandi"),
        accessor: "typeHandi",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="select"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              >
                <option value="">{t("all")}</option>
                <option value="PSYCHIQUE">{t("PSYCHIQUE")}</option>
                <option value="MOTEUR">{t("MOTEUR")}</option>
                <option value="PETITE">{t("PETITE")}</option>
                <option value="MALVOYANT">{t("MALVOYANT")}</option>
                <option value="NONVOYANT">{t("NONVOYANT")}</option>
                <option value="MALENTENDANT">{t("MALENTENDANT")}</option>
                <option value="SOURD">{t("SOURD")}</option>
                <option value="ALBINOS">{t("ALBINOS")}</option>
                <option value="BEGUE">{t("BEGUE")}</option>
                <option value="AUTRE">{t("AUTRE")}</option>
              </Input>
            </FormGroup>
          ),
      },

      {
        Header: t("inscription.paiement"),
        accessor: "paiement",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="select"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              >
                <option value="">{t("all")}</option>
                <option value="EN_ATTENTE">{t("EN_ATTENTE")}</option>
                <option value="PAYE">{t("PAYE")}</option>
                <option value="NON_PAYE">{t("NON_PAYE")}</option>
              </Input>
            </FormGroup>
          ),
        Cell: ({ value }) => (
          <div style={statusStyles(value)}>{getStatusText(value)}</div>
        ),
      },
      {
        Header: t("inscription.paiementCertificat"),
        accessor: "paiementCertificat",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="select"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
              >
                <option value="">{t("all")}</option>
                <option value="EN_ATTENTE">{t("EN_ATTENTE")}</option>
                <option value="PAYE">{t("PAYE")}</option>
                <option value="NON_PAYE">{t("NON_PAYE")}</option>
              </Input>
            </FormGroup>
          ),
        Cell: ({ value }) => (
          <div style={statusStyles(value)}>{getStatusText(value)}</div>
        ),
      },
      {
        Header: t("inscription.inscriptionStatus"),
        accessor: "inscriptionStatus",
        disableFilters: false,
        Filter: ({ column: { id } }) =>
          showFilters && (
            <FormGroup className="filter-input">
              <Input
                ref={(el) => (filterRefs.current[id] = el)}
                id={id}
                type="select"
                placeholder={t("search")}
                onChange={(e) => handleFilterChange(e, id)}
                className="filter-select"
              >
                <option value="">{t("all")}</option>
                <option value="NOUVEAU">{t("NOUVEAU")}</option>
                <option value="EN_COURS_ANALYSE">
                  {t("EN_COURS_ANALYSE")}
                </option>
                <option value="CANDIDAT_ADMIS">{t("CANDIDAT_ADMIS")}</option>
                <option value="CANDIDAT_NON_ADMIS">
                  {t("CANDIDAT_NON_ADMIS")}
                </option>
                <option value="CANDIDATURE_RETENUE">
                  {t("CANDIDATURE_RETENUE")}
                </option>
                <option value="CANDIDATURE_NON_RETENUE">
                  {t("CANDIDATURE_NON_RETENUE")}
                </option>
              </Input>
            </FormGroup>
          ),
        Cell: ({ value }) => (
          <div style={statusStyles(value, true)}>{value}</div>
        ),
      },
      {
        Header: t("actions.title"),
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              color="primary"
              size="sm"
              onClick={() => handleTransactions(row.original.id)}
              title={t("transaction.transactions")}
              style={{
                backgroundColor: "#007bff",
                border: "none",
              }}
            >
              <i
                className="ri-money-dollar-circle-line"
                style={{ fontSize: "16px" }}
              ></i>
            </Button>
            {/* History button */}
            {/* <Button
              color="info"
              size="sm"
              onClick={() => handleHistory(row.original.id)}
              title={t("actions.history")}
            >
              <i className="ri-history-line" style={{ fontSize: "16px" }}></i>
            </Button> */}

            {/* SMS History button */}
            {/* <Button
              color="warning"
              size="sm"
              onClick={() => handleSmsHistory(row.original.id)}
              title={t("actions.smsHistory")}
            >
              <i className="ri-chat-3-line" style={{ fontSize: "16px" }}></i>
            </Button> */}

            {/* Details button */}
            <Button
              color="primary"
              size="sm"
              onClick={() => handleDetails(row.original.numero)}
              title={t("actions.details")}
            >
              <i
                className="ri-information-line"
                style={{ fontSize: "16px" }}
              ></i>
            </Button>
            {/* Delete button */}
            <Button
              color="danger"
              size="sm"
              onClick={() => handleDelete(row.original.id)}
              title={t("actions.delete")}
              style={{
                backgroundColor: "#dc3545",
                border: "none",
              }}
            >
              <i
                className="ri-delete-bin-6-line"
                style={{ fontSize: "16px" }}
              ></i>
            </Button>
          </div>
        ),
      },
    ],
    [t, showFilters]
  );

  const handleHistory = (id) => {
    console.log(id);
    const payload = {
      id,
      onSuccess: (data) => {
        console.log("data", data);
        setHistoryData(data);
        setHistoryModal(true);
      },
      onError: (error) => {
        console.log(error);
      },
    };

    dispatch(inscriptionActions.history(payload));
  };

  const handleTransactions = (id) => {
    console.log(id);
    const payload = {
      id,
      page: 0,
      size: 10,
      onSuccess: (data) => {
        console.log("data", data);
        setHistoryData(data);
        console.log(historyData);
        setTransactionModal(true);
      },
      onError: (error) => {
        console.log(error);
      },
    };

    dispatch(inscriptionActions.transaction(payload));
  };

  const handleSmsHistory = (id) => {
    console.log(id);
    const payload = {
      id,
      onSuccess: (data) => {
        console.log("data", data);
        setHistoryData(data);
        console.log(historyData);
        setSmsHistoryModal(true);
      },
      onError: (error) => {
        console.log(error);
      },
    };

    dispatch(inscriptionActions.historySms(payload));
  };

  const handleDetails = (numero) => {
    // Navigate to the details page with the inscription ID
    navigate(`/portail/${numero}?token=${localStorage.getItem("token")}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e) ?",
      text: "Cette action supprimera définitivement cette candidature. Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id,
          onSuccess: () => {
            dispatch(
              inscriptionActions.search({
                criteria,
                page: page,
                size: pageSize,
              })
            );
            Swal.fire({
              title: "Supprimé !",
              text: "La candidature a été supprimée avec succès.",
              icon: "success",
              confirmButtonText: "OK",
            });
          },
          onError: (error) => {
            Swal.fire({
              title: "Erreur !",
              text:
                error.message ||
                "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
              icon: "error",
              confirmButtonText: "OK",
            });
            console.log("Error : ", error);
          },
        };

        dispatch(inscriptionActions.delete(payload));
        console.log(payload);
      }
    });
  };

  const getItemRange = () => {
    const start = page * 10 + 1;
    const end = Math.min((page + 1) * 10, totalElements);
    return `${start}-${end} of ${totalElements}`;
  };

  const resetFilters = () => {
    // Reset criteria state to empty strings
    setCriteria({ ...initQuery });

    dispatch(
      inscriptionActions.filter({
        criteria: { ...initQuery },
        page: page,
        size: pageSize,
      })
    );
  };

  const handleExportExcel = () => {
    let payload = {
      criteria,
      page: pageNumber,
      size: pageSize,
      onSuccess: (data) => {
        console.log(data);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = "inscriptions.xlsx";
        link.click();

        Swal.fire({
          icon: "success",
          title: "Exportation réussie",
          text: "Le fichier Excel des inscriptions a été généré et téléchargé avec succès.",
          confirmButtonText: "Super !",
        });
      },
      onError: (error) => {
        console.error(error);

        Swal.fire({
          icon: "error",
          title: "Échec de l'exportation",
          text: `Une erreur s'est produite lors de la génération du fichier des inscriptions. Veuillez réessayer plus tard.
                 Erreur : ${error.message || "Erreur inconnue"}`,
          footer:
            '<a href="mailto:support@example.com">Contacter le support</a>',
          confirmButtonText: "Ok",
        });
      },
    };
    dispatch(inscriptionActions.generateExcelInscription(payload));
  };
 const hasRole = (role) => {
    const authUser = localStorage.getItem("authUser");
    const parsedAuthUser = JSON.parse(authUser);
    const decoded = jwtDecode(parsedAuthUser?.accessToken);
    const roles = decoded?.roles ?? [];
    console.log(roles);
    const found = [role]?.some((r) => roles?.indexOf(r) >= 0);
    return found;
  };
  const isAdmin = hasRole("ROLE_ADMIN") || hasRole("ROLE_SUPER_ADMIN");

  return (
    <React.Fragment>
      {(isFetching || loading) && (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      )} 
        <div className="page-content">
          <Container fluid>
          {isAdmin ? <AdminNav /> : null}

            <Card>
              <h3 className="m-4">{t("inscription.candidatures")}</h3>
              <CardBody>
                <div className="pagination-container">
                  <span className="pagination-range">{getItemRange()}</span>
                  <button
                    onClick={handlePrevPage}
                    disabled={page === 0}
                    className="pagination-icon-button"
                  >
                    <FaChevronLeft />
                  </button>

                  <button
                    onClick={handleNextPage}
                    disabled={page >= totalPages - 1}
                    className="pagination-icon-button"
                  >
                    <FaChevronRight />
                  </button>
                  {/* Excel Export Button */}
                  <button
                    onClick={handleExportExcel} // Make sure to implement this function
                    color="primary"
                    className="excel-button"
                  >
                    <FaFileExcel style={{ marginRight: "5px" }} />
                    {t("Excel")}
                  </button>
                  <button
                    onClick={toggleFilters}
                    color="primary"
                    style={{
                      backgroundColor: showFilters ? "#4f46e5" : "",
                      color: showFilters ? "#ffffff" : "",
                    }}
                    className="filter-button"
                  >
                    <FaFilter style={{ marginRight: "5px" }} />{" "}
                    {showFilters
                      ? t("filter.hideFilters")
                      : t("filter.showFilters")}
                  </button>

                  <button
                    onClick={resetFilters}
                    color="primary"
                    className="filter-button"
                  >
                    <FaUndo style={{ marginRight: "5px" }} />
                    {t("filter.resetFilters")}
                  </button>
                </div>

                <TableContainer
                  columns={columns}
                  data={inscriptions ?? []}
                  isPagination={false}
                  customPageSizeOptions={true}
                  iscustomPageSize={false}
                  isBordered={false}
                  customPageSize={10}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      
      <Modal
        size="lg"
        isOpen={smsHistoryModal}
        toggle={() => setSmsHistoryModal(false)}
      >
        <ModalHeader toggle={() => setSmsHistoryModal(false)}>
          {t("actions.smsHistory")}
        </ModalHeader>
        <ModalBody>
          {historyData && historyData.length > 0 && (
            <Table striped>
              <thead>
                <tr>
                  <th>{t("sms.date")}</th>
                  <th>{t("sms.action")}</th>
                  <th>{t("sms.telephone")}</th>
                  <th>{t("sms.status")}</th>
                </tr>
              </thead>
              <tbody>
                {(historyData || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item?.createdDate}</td>
                    <td>{item?.action}</td>
                    <td>{item?.telephone}</td>
                    <td>{item?.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={historyModal}
        toggle={() => setHistoryModal(false)}
      >
        <ModalHeader toggle={() => setHistoryModal(false)}>
          {t("actions.history")}
        </ModalHeader>
        <ModalBody>
          {historyData && historyData.length > 0 && (
            <Table striped>
              <thead>
                <tr>
                  <th>{t("history.createdDate")}</th>
                  <th>{t("history.statusBefore")}</th>
                  <th>{t("history.statusAfter")}</th>
                </tr>
              </thead>
              <tbody>
                {(historyData || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item?.createdDate}</td>
                    <td>{item?.statusBefore}</td>
                    <td>{item?.statusAfter}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ModalBody>
      </Modal>
      <Modal
        size="xl"
        isOpen={transactionModal}
        toggle={() => setTransactionModal(false)}
      >
        <ModalHeader toggle={() => setTransactionModal(false)}>
          {t("transaction.transactions")}
        </ModalHeader>
        <ModalBody>
          {historyData && historyData.length > 0 && (
            <Table striped>
              <thead>
                <tr>
                  <th>{t("inscription.numero")}</th>
                  <th>{t("transaction.createdDate")}</th>
                  <th>{t("transaction.externalId")}</th>
                  <th>{t("transaction.telephone")}</th>
                  <th>{t("transaction.amount")}</th>
                  <th>{t("transaction.typePaiment")}</th>
                  <th>{t("transaction.status")}</th>
                </tr>
              </thead>
              <tbody>
                {(historyData || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item?.inscriptionNumero}</td>
                    <td>{item?.createdDate}</td>
                    <td>{item?.externalId}</td>
                    <td>{item?.partyId}</td>
                    <td>{item?.amount}</td>
                    <td>{getStatusText(item?.typePaiment)}</td>
                    <td>{getStatusText(item?.status)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Transactions;
