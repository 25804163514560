import React from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6} style={{ fontSize: "14px" }}>
                            {new Date().getFullYear()} © HANDI-DPPH
                        </Col>
                        <Col sm={6} style={{ fontSize: "14px" }}>
                            <div className="text-sm-end d-none d-sm-block">
                                Développé par <a href="https://ideracloud.com" target="_blank">IDERACLOUD</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
